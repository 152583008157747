<template>
    <div class="customtag flex flex_wrap align_center">
        <div class="relative width_fit_content height_fit_content tag_panel" v-for="(info, idx) in tags" :key="'tag_' + idx">
            <Tag style="visibility: hidden">{{ info }}</Tag>
            <Input
                clearable
                :disabled="disabled"
                :readonly="tagsStatus[idx] == 0"
                v-model.trim="tags[idx]"
                style="height: 36px !important"
                class="pages"
                @on-clear="onCloseTag(idx)"
                @on-focus="onChange(idx)"
                @on-blur="onConfirmChange(idx)"
                @on-enter="onConfirmChange(idx)"
            />
        </div>
        <Input :disabled="disabled" ref="input" v-show="showInput" v-model.trim="inputTxt" style="width: 200px" autofocus @on-blur="onConfirmInput" @on-enter="onConfirmInput" />
        <Button class="ml_10" :disabled="disabled" v-show="!showInput" type="dashed" size="small" @click="onShowInput">
            <Icon type="md-add" />
            添加
        </Button>
    </div>
</template>
<script>
export default {
    name: 'tag',
    props: {
        list: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showInput: false,
            inputTxt: null,
            tags: [],
            tagsStatus: [],
        };
    },
    watch: {
        list: {
            handler(n) {
                // console.log('watch list new value=========>', n);
                this.tags = n;
                this.tags.forEach((info, idx) => {
                    this.tagsStatus[idx] = 0;
                });
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        onCloseTag(idx) {
            this.tags.splice(idx, 1);
            this.tagsStatus.splice(idx, 1);
            this.$emit('change', this.tags);
        },
        onShowInput() {
            this.inputTxt = null;
            this.showInput = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        onConfirmInput() {
            let inputTxt = this.inputTxt || null;
            if (inputTxt && this.tags.findIndex(tag => tag == inputTxt) == -1) {
                this.tags.push(inputTxt);
                this.tagsStatus.push(0);
                this.$emit('change', this.tags);
            }
            this.inputTxt = null;
            this.showInput = false;
        },
        onChange(idx) {
            console.log('onChange...', idx);
            this.setStatus(idx, 1);
        },
        onConfirmChange(idx) {
            this.setStatus(idx, 0);
            let str = this.tags[idx] || null;
            if (!isNotEmptyString(str)) {
                this.onCloseTag(idx);
            }
            this.$emit('change', this.tags);
        },
        setStatus(idx, val) {
            this.tagsStatus[idx] = val;
            this.tagsStatus.push(0);
            this.tagsStatus.pop();
        },
    },
};
</script>
<style lang="less" scoped>
.customtag .ivu-tag-text {
    white-space: nowrap;
    display: block;
}
.customtag {
    padding: 8px 10px;
    min-height: 56px;
    min-width: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}
.customtag .ivu-tag {
    height: 32px;
    padding: 4px 8px;
}
.customtag .ivu-input {
    height: 36px !important;
}
.customtag .ivu-btn {
    height: 32px;
    margin: 2px 4px 2px 0;
}
.customtag .tag_panel {
    min-width: 150px;
    max-width: 500px;
    margin-right: 10px;
}
</style>
