<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <!-- <div class="form_item">
            <div class="form_label must_input">所属分类</div>
            <Cascader :transfer="true" :data="classTypeList" trigger="hover" v-model="cidArr" placeholder="请选择所属分类"></Cascader>
        </div> -->
        <div class="form_item">
            <div class="form_label must_input">参数项名称</div>
            <Input class="width_300" type="text" v-model.trim="formData.title" :maxlength="15" show-word-limit placeholder="请输入参数项名称"></Input>
        </div>
        <div class="form_item">
            <div class="form_label">是否显示</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">是</Radio>
                <Radio label="0">否</Radio>
            </RadioGroup>
            <div class="tip_txt">（启用后，将在小程序端商品详情页展示）</div>
        </div>
        <div class="form_item">
            <div class="form_label must_input">参数类型</div>
            <Select class="width_300" v-model="formData.type" @on-change="onChangeType" placeholder="请选择参数类型">
                <Option v-for="info in paramType" :key="info.value" :value="info.value">{{ info.label }}</Option>
            </Select>
        </div>
        <div v-if="formData.type == paramsTypeDef.text" class="form_item">
            <div class="form_label">文本提示</div>
            <Input class="width_300" type="text" v-model.trim="placeholder" :maxlength="30" show-word-limit></Input>
        </div>
        <div v-else-if="formData.type == paramsTypeDef.single || formData.type == paramsTypeDef.multiple" class="form_item">
            <div class="form_label align_self_start">选项</div>
            <tag :list="options || []" @change="msg => onTaggroupChange(options, msg)"></tag>
        </div>

        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import tag from '@/components/tag.vue';
import { getParamType, type_array, type_null } from '@/lib/util';
import { paramType, paramsTypeDef, getClassListData } from './common';
import { reqGoodsSaveParameter } from '@/lib/request/auth2';
// import {  } from '@/lib/request/auth';

export default {
    name: 'goodParamsAdd',
    components: {
        tag,
    },
    data() {
        this.paramsTypeDef = paramsTypeDef;
        this.paramType = paramType;
        return {
            title: null,
            maxLength: 5,
            formData: {
                id: null,
                cid: null,
                pcid: null,
                title: null,
                status: '1',
                type: '1',
                type_info: null,
            },
            cidArr: [],
            placeholder: null,
            options: [],
            classTypeList: [],
            isEdit: false,
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        this.title = this.isEdit ? '编辑商品参数' : '添加商品参数';
        this.getClassTypeList();
        // console.log(JSON.stringify(this.formData, null, '\t'));

        if (!!this.formData.id) {
            let { pcid, cid, type, type_info } = this.formData;
            this.cidArr = [pcid, cid];
            type_info = JSON.parse(type_info);
            if (type == paramsTypeDef.text) {
                this.placeholder = type_info.placeholder;
            } else {
                this.options = type_info.options || [];
                console.log('options', this.options);
            }
        }
    },
    watch: {
        //
    },
    methods: {
        onTaggroupChange(info, list) {
            info = list;
            // console.log('onTaggroupChange info = ', info);
        },
        //获取分类列表数据
        getClassTypeList() {
            getClassListData().then(list => {
                this.classTypeList = list;
            });
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, type } = params || {};
            let vs = [{ value: title, tip: '请输入参数项名称' }];

            // let cidArr = this.cidArr || [];
            // let typeId_type = getParamType(cidArr);
            // if (typeId_type == type_null || (typeId_type == type_array && cidArr.length <= 0)) {
            //     this.fadeWarning('请选择所属分类');
            //     this.hideAjaxLoading();
            //     return;
            // }
            // params.cid = cidArr.slice(-1)[0];

            hm.validateForm(vs)
                .then(() => {
                    this.hideAjaxLoading();

                    let options = this.options,
                        placeholder = this.placeholder;
                    if (type == paramsTypeDef.single || type == paramsTypeDef.multiple) {
                        let options_type = getParamType(options);
                        if (options_type == type_null || (options_type == type_array && options.length <= 0)) {
                            this.fadeWarning('至少添加一个选项');
                            return;
                        }
                    } else if (type != paramsTypeDef.text) {
                        this.fadeWarning('请选择参数类型');
                        return;
                    }

                    if (type == paramsTypeDef.multiple || type == paramsTypeDef.single) {
                        params.type_info = {
                            options,
                        };
                    } else {
                        params.type_info = {
                            placeholder,
                        };
                    }
                    params.type_info = JSON.stringify(params.type_info);
                    console.log(JSON.stringify(params, null, '\t'));

                    reqGoodsSaveParameter(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/goodParams');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        //选择参数类型
        onChangeType() {
            this.placeholder = null;
        },
    },
};
</script>
<style lang="less" scoped></style>
