import { render, staticRenderFns } from "./goodParamsAdd.vue?vue&type=template&id=66889d4e&scoped=true&"
import script from "./goodParamsAdd.vue?vue&type=script&lang=js&"
export * from "./goodParamsAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66889d4e",
  null
  
)

export default component.exports